import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        let encodedJsonString = token;
        // encodedJsonString = CryptoJS.AES.encrypt(encodedJsonString, 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString();
        localStorage.setItem('kf', encodedJsonString);
      },
      () => {
        let randomString: string = uuidv4();
        // randomString = CryptoJS.AES.encrypt(randomString, 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString();
        localStorage.setItem('kf', randomString);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}
