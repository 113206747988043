<div class="container-fluid row">


      <div class="col-md-6 login-img-div p-3 border-right">
        <img src="../../../assets/img/registration_bg.png" alt="" height="550" width="600">
      </div>
  
      <div  class="col-md-6 login-section-div d-flex flex-column">

        <div class="login-close" (click)="onCloseDrawer()">
          <mat-icon class="close_icon m-4 float-right">close</mat-icon>
        </div>

        <div class="mt-2 mr-5 mb-3 ml-3 justify-content-center align-items-center">

        <h1 class="nushift-black mb-4 hello"> <strong>{{registrationHeading}}</strong></h1>
        <!-- MOBILE/EMAIL VERIFICATION FORM -->
        <form [formGroup]="firstFormGroup" style="width: 100%; " *ngIf="showCreatePassword===false" class="">
          <!-- <div class="row"> -->
            <!-- <div class="col-9" style="margin: 0;padding: 0;"> -->
  
            <!-- PHONE NUMBER/EMAIL FIELD -->
            <div class="col-md-12 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Enter
                  Phone Number/Email <span class="text-danger">*</span></mat-label>
                <input matInput formControlName="mobile" class="mt-3" [readonly]="disableMobile" />
              </mat-form-field>
            </div>
  
            <!-- USER TYPE FIELD -->
            <!-- <mat-form-field appearance="standard" class="w-100">
                    <mat-label>Enter Phone/Email<span class="text-danger">*</span></mat-label>
                    <input matInput formControlName="mobile" [readonly]="disableMobile" />
                  </mat-form-field> -->
            <div class="col-md-12 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;margin-top: 0.5rem;font-size: 20px !important;">Register as<span class="text-danger">*</span></mat-label>
                <mat-select formControlName="user_type" class="mt-3">
                  <mat-option value="D">Doctor</mat-option>
                  <mat-option value="H">Hosptial</mat-option>
                  <mat-option value="L">Diagnostic Center</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
  
            <!-- </div> -->
            <button type="submit" *ngIf="!isOtpVerified && !showOTPBox" class="w-100 btn btn-block sign-in-btn" style="margin-top: 10px"
              [disabled]="firstFormGroup.invalid" (click)="mobileInviteCheck()">
              Submit
            </button>
            <!-- <mat-spinner diameter="20" *ngIf="showSpinner === true"></mat-spinner> -->
            <p *ngIf="isOtpVerified" style="margin: 0;padding: 0; color: green;text-align: right;">Verified
            </p>
        </form>

          
        <!-- VALIDATE OTP FORM -->
        <form [formGroup]="otpFormGroup" class="w-100" *ngIf="showOTPBox && showCreatePassword===false">
          <!-- <div class="row border-box"> -->
  
            <!-- OTP FIELD -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;margin-bottom: 0.5rem !important;">Enter OTP<span class="text-danger">*</span></mat-label>
                <input matInput formControlName="OTP" class="mt-3" [readonly]="disableMobile" maxlength="4"/>
              </mat-form-field>
            </div>
            <!-- *ngIf="showOTPSpinner === false " -->
            <button class="w-100 btn btn-block sign-in-btn mt-3" *ngIf="!isOtpVerified" (click)="onOTPVerifyClick()" [disabled]="otpFormGroup.invalid">
              Verify</button>
            <!-- <mat-spinner diameter="20" *ngIf="showOTPSpinner === true"></mat-spinner> -->
          <!-- </div> -->
        </form>
        <!-- <h4 *ngIf="otpStatusMessage && showCreatePassword===false" class="align-self-start" style="color: red;">
          {{otpStatusMessage}}</h4> -->
         
        <!-- REGISTRATION FORM -->
        <div class="container-fluid-otp-v-block mt-3" *ngIf="isOtpVerified && showCreatePassword===false">
          <form [formGroup]="registerForm" (ngSubmit)="register()" class="form-container w-100">
  
            <!-- FIRST NAME -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">First Name<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="firstName" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <!-- LAST NAME -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Last Name<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="lastName" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <!-- EMAIL ADDRESS -->
            <div class="w-100 mt-2" *ngIf="showEmail">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Email<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="emailAddress" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <!-- PHONE NUMBER -->
            <div class="w-100 mt-2" *ngIf="showMobile">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Phone Number<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="mobile" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <!-- GENDER -->
            <div class="w-100 mt-2">
              <mat-form-field floatLabel='always' appearance="standard" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Gender<span
                    class="text-danger">*</span></mat-label>
                <mat-select formControlName="gender" class="mt-2">
                  <mat-option value="">--Select Gender--</mat-option>
                  <mat-option value="male">Male</mat-option>
                  <mat-option value="female">Female</mat-option>
                  <mat-option value="others">Others</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <select formControlName="specialization" class="border-box d-block form-select">
                        <option value="">Select Specialization</option>
                        <option *ngFor="let specialization of specializations" [value]="specialization.id">
                          {{specialization.name}}
                        </option>
                      </select> -->
  
            <!-- REGISTRATION NUMBER -->
            <!-- <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Registration Number<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="regNumber" class="mt-2"/>
              </mat-form-field>
            </div> -->
  
            <!-- INVITATION CODE -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Invitation Code<span
                    class="text-danger">*</span></mat-label>
                <input matInput formControlName="invitationCode" class="mt-2" [disabled]="true"/>
              </mat-form-field>
            </div>
            <!-- *ngIf="showRegisterSpinner === false" -->
  
            <!-- REGISTER BUTTON -->
            <button type="submit" class="w-100 btn btn-block sign-in-btn mt-3">Register</button>
            <!-- <mat-spinner diameter="20" *ngIf="showRegisterSpinner === true"></mat-spinner> -->
          </form>
          <!-- <h4 *ngIf="registerStatusMessage && showCreatePassword===false" class="align-self-start" style="color: red;">
            {{registerStatusMessage}}
          </h4> -->
  
        </div>
  
        <!-- CREATE PASSWORD FORM -->
        <div *ngIf="showCreatePassword">
          <form [formGroup]="createPasswordForm" (ngSubmit)="onCreatePassword()" class="form-container">
  
            <!-- PASSWORD FIELD -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">New Password<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="password" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <!-- CONFIRM PASSWORD FIELD -->
            <div class="w-100 mt-2">
              <mat-form-field appearance="" floatLabel="always" class="w-100">
                <mat-label style="font-weight: 600; color: black;font-size: 20px !important;">Confirm Password<span
                    class="text-danger">*</span></mat-label>
                <input matInput [required]="true" formControlName="newPassword" class="mt-2"/>
              </mat-form-field>
            </div>
  
            <button type="submit" class="btn btn-nushift-blue btn-block" [disabled]="createPasswordForm.invalid"
              *ngIf="showPasswordSpinner === false">Submit</button>
            <mat-spinner diameter="20" *ngIf="showPasswordSpinner === true"></mat-spinner>
  
          </form>
          <h4 *ngIf="passwordStatusMessage" class="align-self-start" style="color: red;">{{passwordStatusMessage}}
          </h4>
        </div>


        <div class="w-100 d-flex justify-content-center font-size-5 my-3">
            <!-- <div>Already
            Registered?</div>
          <a [routerLink]="['/landing']" class="nushift-blue ml-3"> Login</a>
        </div> -->

        <span class="nushift-black mt-2" id="new-user-label">Already Registered?
          <a class="ml-2 cursor nushift-blue bold" [routerLink]="['']" id="register-label"
            style="font-weight: 600">Login</a>
        </span>

        </div>
        <!-- </div> -->
      </div>
    <!-- </div> -->
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="ball-atom" [fullScreen]="false">
      <b style="color: white"> Loading... </b>
    </ngx-spinner>