import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Safely check for the existence of the uid
    const user = this.authService.userValue; // Fetch user data from AuthService
    if (user && user.uid) {
      return true; // UID exists, allow route access
    } else {
      // If UID does not exist, redirect to a specific page
      this.router.navigate(['']); // Redirect to landing or login page
      return false; // Prevent route access
    }
  }
}
