import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  isOTPVerified: boolean;
  emailValidators: ValidatorFn[] = [      // Setting Validators for Email field
    Validators.pattern(
      '^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'
    ),
  ];
  hide: boolean = true;
  hide_two: boolean = true;
  disableButton: boolean = false;
  flagButton: boolean;
  validators: ValidatorFn[] = [Validators.required];
  private uid: string;
  generatedOTPResponse: any;
  showOtpField: boolean = false;
  verifiedOTPResponse: any;
  verifiedOtpField: boolean = false;
  updatedPasswordResponse: any;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public nav: NavbarService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private apiEndPointService: ApiEndpointsService
  ) {
    this.forgotForm = this.fb.group({   // Forgot password formgroup
      email: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      otp: ['', Validators.required],
      user_type: ['']
    });
  }

  ngOnInit(): void {
    this.nav.navbarHide();  // To hide the navbar menu
    this.nav.footerShow();   // To show the footer at the bottom of the screen
    this.nav.setfooterFixed();  // Setting the footer at the bottom fixed
  }

  // public addChild(childName: string, abstractControl: any) {
  //   this.forgotForm.addControl(childName, abstractControl);
  // }

  // submit() {
  //   this.disableButton = true;
  //   this.isOTPVerified
  //     ? this.verifyOtp(this.forgotForm.value.otp)
  //     : this.generateOTP(this.forgotForm.value.email);
  // }

  async generateOTP(userId: string) {     // Method to send otp to the apicall
    let payload = {
      user_name: this.forgotForm.value.email,
      user_type: this.forgotForm.value.user_type
    };
    this.spinner.show();
    (await this.authService.forgotPasswordOTP(payload)).subscribe((res) => {
      if (res.status && res.data) {
        this.spinner.hide();
        this.openSnackBar(res.message, '');
        this.isOTPVerified = true;
        this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
          data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
          this.generatedOTPResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
          if (this.generatedOTPResponse.uid) this.uid = this.generatedOTPResponse.uid;
          console.log(this.generatedOTPResponse);
          this.showOtpField = true;
        });
      } else {
        this.spinner.hide();
        this.openSnackBar(res.message, '');
        if (res.status) this.showOtpField = true;
      }
      // if (res.status) {
      //   this.openSnackBar(res.message, '');
      // } else {
      //   this.openSnackBar(res.message, '');
      // }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wring! Please try again', '');
    });
  }

  async verifyOtp(otp: string) {      // Method to verify entered otp 
    let payload = {
      mobile: this.forgotForm.value.email,
      otp: Number(this.forgotForm.value.otp)
    }
    this.spinner.show();
    (await this.authService.verifyOtpV2(payload)).subscribe((res) => {
      if (res.status && res.data) {
        this.spinner.hide();
        this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
          data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
          this.verifiedOTPResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
          console.log(this.verifiedOTPResponse);
          if (res.status) this.verifiedOtpField = true;
        });
      } else {
        this.spinner.hide();
        this.openSnackBar(res.message, '');
        if (res.status) this.verifiedOtpField = true;
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wrong! Please try again', '');
    });
  }

  async updatePassword() {    // Method to update the new password entered by the user
    let payload = {
      user_name: this.forgotForm.get('email').value,
      new_password: this.forgotForm.get('new_password').value,
      confirm_password: this.forgotForm.get('confirm_password').value
    };
    this.spinner.show();
    (await this.authService.updatePassword(payload)).subscribe((res) => {
      if (res.status && res.data) {
        this.spinner.hide();
        this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
          data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
          this.updatedPasswordResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
          console.log(this.updatedPasswordResponse);
          if (res.status) {
            this.openSnackBar(res.message, '');
            this.router.navigateByUrl('');
          }
        });
      } else {
        this.spinner.hide();
        this.openSnackBar(res.message, '');
        res.status ? this.router.navigate(['']) : false;
      }
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wrong! Please try again', '');
    });
  }

  openSnackBar(message: string, action: string): void {   // Method to show the alert message
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
